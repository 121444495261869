@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.money-withdrawal {
  &__container {
    color: $mako;
    font-family: Nunito;
    font-style: normal;
    gap: 1rem;
    margin-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 96px;
  }

  &__intro__container {
    display: grid;
    margin-top: 24px;
    gap: 24px;
  }

  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    padding: 0px 10px;
    gap: 16px;
    border-left: 7px solid $congress-turquoise;

    @media screen and (max-width: $desktop) {
      margin: 0px;
      font-size: 20px;
    }
  }

  &__subtitle {
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
    padding-right: 16px;
    padding-left: 16px;
    gap: 16px;

    @media screen and (max-width: $desktop) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &__row__container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-left: 16px;
    padding-right: 16px;
    margin-block-start: 24px;
    margin-block-end: 12px;

    @media screen and (max-width: $desktop) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  &__row {
    display: flex;
    flex: 1 1 100%;
    gap: 24px;
    margin-bottom: 12px;
  }

  @media screen and (max-width: $desktop) {
    &__row {
      flex-direction: column;
    }
  }

  @media screen and (min-width: $desktop) {
    &__row {
      flex: 1 1 50%;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    &__same__row {
      padding: 12px 16px 12px 16px;
      display: flex;
      flex-direction: row;
      background-color: $white;
      border: none;
      border-radius: 8px;
    }
  }

  &__row__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &__column__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-block-start: 0;
    margin-block-end: 12px;
    color: $congress-turquoise
  }

  &__available__money {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    padding-top: 16px;
    color: $congress-turquoise;
  }

  &__input__container {
    height: 48px;
    border-radius: 8px;
    background-color: $white;
    margin-bottom: 0;
    padding: 12px 16px 12px 16px;


    &__small {
      width: 10% !important;

      @media screen and (max-width: $desktop) {
        width: 20% !important;
      }
    }

    &__medium {
      width: 73% !important;
      padding-right: 16px;

      @media screen and (max-width: $desktop) {
        width: 63% !important;
      }
    }

    &__error {
      border: 1.5px solid #FF7D00;
      border-radius: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    &__document__field {
      margin-bottom: 0;
      height: 24px;
    }

    &__amount__field {
      border-left: none;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-bottom: 0;

      &__divider {
        height: 24px;
        width: 2px;
        background-color: #475061;
        position: relative;

        &__disabled {
          background-color: #D4D7D9;
        }

      }

      input {
        padding: 0px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        font-family: Nunito;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: end;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    &__text__alert {
      color: $amber;
      font-family: Nunito;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    &__prefix {
      display: flex;
      align-items: center;
      background-color: $white;
      white-space: nowrap;
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-right: none;
      padding-right: 16px;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

  }

  &__input__withdrawal {
    height: 24px;
    gap: 16px;
    padding: 0px;
    background-color: $white;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $mako;
    flex: 1;
    box-sizing: border-box;

    &:disabled {
      color: #A2AAAD;
    }

    &__no__radius {
      border-radius: 0px;
    }

    &__no_left__radius {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &__align__left {
      text-align: end;
    }

    &__align__center {
      text-align: center;
    }
  }

  &__select__bank__type {
    div:has(>ul){
      @media screen and (max-width: $desktop) {
        width: 100% !important;
      }
    }
  }

  &__select__bank {
    #bank, #bankAccountType{
      p{
        font-family: "Nunito";
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $mako;
        padding-right: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      font-family: "Nunito";
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      height: 48px;
      width: 511px;
      background-color: $white;
      border: none;
      border-radius: 10px;
      color: $mako;
      flex: 1;
      box-sizing: border-box;


      @media screen and (max-width: $desktop) {
        width: 317px;
        p{
          padding-right: 20px;
        }
      }
    }

    &__error {
      #bank, #bankAccountType{
        border: 1.5px solid #FF7D00;
      }
    }

    div:has(>ul){
      @media screen and (max-width: $desktop) {
        width: 95%;
      }
    }

    ul {
      background: none;
    }

    ul li {
      color: #475061;
      font-family: "Nunito";
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      gap: 16px;
      padding-top: 8px;
      padding-right: 12px;
      padding-bottom: 8px;
      padding-left: 16px;
      border: 1px solid #D4D7D9;

      &:first-child{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    
      &:last-child{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    ul li:hover {
      background-color: #F2F2F3;
    }
  }

  &__select__withdrawal {

    select {
      font-family: "Nunito";
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      height: 48px;
      padding: 8px 16px 8px 16px;
      background-color: $white;
      border: none;
      border-radius: 10px;
      color: $mako;
      flex: 1;
      box-sizing: border-box;

      &:disabled {
        color: #A2AAAD;
      }
    }

    &::after {
      top: 20px !important;
      right: 0px !important;

      @media screen and (max-width: $desktop) {
        right: 16px !important;
      }
    }

    &__document__type {
      height: 24px;

      &::after {
        top: 8px !important;
        width: 16px !important;
        height: 10px !important;
        right: 16px !important;
  
        @media screen and (max-width: $desktop) {
          right: 16px !important;
          top: 8px !important;
        }
      }

      select {
        height: 24px;
        width: 53.86px;
        padding: 0px;
      }
    }

    &__error {
      padding-block-end: 0px;

      select {
        border: 1.5px solid #FF7D00;
      }
    }

    &__small {
      padding-right: 0px;
      width: 16% !important;

      @media screen and (max-width: $desktop) {
        width: 32% !important;
      }

      select {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &_natural {
        width: 18% !important;
        
        @media screen and (max-width: $desktop) {
          width: 35% !important;
        }
      }
    }
  }

  &__stroke__line__container {
    margin-left: 15px;

    &__stroke__circle {
      height: 15px;
      width: 15px;
      background: none;
      border-radius: 50%;
    }

    &__stroke__line {
      height: 25px;
      width: 1px;
      margin-left: 7px;
      background: none;

      @media screen and (max-width: $desktop) {
        height: 16px;
      }
    }
  }

  &__middle__text {
    text-align: left;
    margin-bottom: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    padding-right: 16px;
    padding-left: 16px;
    @media screen and (max-width: $desktop) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  &__footer__text {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    padding-right: 16px;
    padding-left: 16px;

    @media screen and (max-width: $desktop) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  &__button__container {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 72px;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bright-turquoise-transparent;

    @media screen and (max-width: $desktop) {
      height: 60px;
    }
  }

  &__button {
    width: 215px;
    height: 49px;
    background-color: $white;
    border-radius: 24px;
    border: none;
    color: $bright-turquoise;
    font-family: "Nunito";
    font-size: 17px;
    font-weight: 800;
    outline: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    @media screen and (max-width: $desktop) {
      width: 170px;
      height: 40px;
      border-radius: 18px;
      font-size: 13px;
    }
  }

  &__back_container {
    margin-top: 24px;
    margin-left: 13px;
  }


  &__go__back {
    border: none;
    border-radius: 48px;
    background-color: $white;
  }

  &__balance-content-button {
    display: flex;
    flex-direction: row;
    background-color: $white;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    border: none;
    border-radius: 48px;
    width: 76px;
    height: 24px;
    flex: none;
    cursor: pointer;
    order: 0;
    flex-grow: 0;
    margin: 12px 24px;

    p {
      position: static;
      width: 48px;
      height: 24px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #475061;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 8px;
    }

    &__icon {
      color: #475061;
      margin: 0px 8px;
    }
  }
}